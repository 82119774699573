.App {
  text-align: center;
  position: relative;
  padding-left: 16px;
  padding-top: 20px;
}

.App-logo-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
  z-index: 1000;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.App-content {
  margin-top: 30px;
  padding-left: 20px;
}

.App-logo {
  height: 75px;  /* Adjust as needed */
}

.default-bg {
  background-color: #f2f2f2;
}

.custom-bg {
  background-color: #f2f2f2;
}


.kpi-section h2 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.kpi-section {
  border: 2px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #007ba7;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.annual-kpis {
  padding-top: 20px;
}

.annualized-kpis {
  padding-top: 20px;
}

.finance-bttn.btn {
  font-size: 0.7rem;
}

.investment-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.calculator-card {
  color: #f2f2f2; /* Set text color to white */
  text-align: left;
}

/* Style for cards that look like table rows */
.investment-profile-row {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.save-button-container {
  display: flex;
  justify-content: flex-start;  /* Aligns the button to the left */
  margin-top: auto;  /* Pushes the button to the bottom */
}

.table-container {
  max-height: 500px; /* Adjust based on your preference */
  overflow-x: auto;
  overflow-y: auto;
}

.table thead th {
  position: sticky;
  top: 0; /* Stick to the top */
  background-color: #f2f2f2; /* Ensure text is readable */
  z-index: 10; /* Optional: Adjust if needed to bring header above other content */
}

.table-custom thead th {
  background-color: #7447EF;
  color: white;
}

.table-custom thead th {
  background-color: #003153;
  color: #f2f2f2;
}

.login-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
}

.login-button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1500;
  margin-left: auto; /* Align to the right */
  align-self: center; 
}

.login-button:hover {
  background-color: #0069d9;
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 0 !important;  /* Override with !important */
  padding-left: 0 !important;   /* Override with !important */
  margin-top: var(--bs-gutter-y);
}

.navy-blue-background {
  background-color: #003153;
  color: #003153; /* White text */
}

.table-.btn-left-justify {
  display: block;
  text-align: left;
}

.table-actions-bg {
  text-align: left;
  background-color: #003153;
}

.table-actions .btn-custom-danger, 
.table-actions .btn-custom-primary,
.table-actions .btn-custom-info {
    background-color: #f2f2f2; /* Light gray background */
    color: #333; /* Dark text color for visibility */
    border-color: #ccc; /* Optional: Light border for some definition */
    padding: 4px 6px; /* Smaller padding */
    font-size: 14px; /* Smaller font size */
    border: 1px solid #ccc; /* Subtle border */
    color: #333; /* Darker text for contrast */
    margin: 12px 1px; 
}

.table-actions .btn-custom-danger:hover, 
.table-actions .btn-custom-primary:hover,
.table-actions .btn-custom-info:hover {
    background-color: #e8e8e8;
}

.metric-label {
  font-size: 0.8rem;
  text-align: left; /* Align label text to the left */
}

.form-control.input-field {
  width: 75%; /* Set the desired width */
}

.d-flex.align-items-center {
  justify-content: flex-end;
}

.label-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the group takes full width */
  padding-right: 5px;
}

input::placeholder {
  font-size: 0.75rem;
}

.comparison-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}

.comparison-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  align-items: start;
}

.property-column {
  border: 1px solid #ccc;
  padding: 10px;
}

.comparison-column {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f7f7f7;
}

.nav-bar {
  display: flex;
  flex-direction: column;
  background-color: #001a33;
  width: 3%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  padding-top: 40px;
}



.nav-item {
  color: white;
  padding-top: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon {
  font-size: 24px; /* Adjust icon size as needed */
}

.selected-analysis {
  background-color: #f0f0f0; /* Or any other highlight color */
  color: #333;
  font-weight: 600;
}

.forecast-side-panel  {
  background-color: #003153;
  color: #f2f2f2;
}

.forecasting-layout {
  display: flex;
  height: 100vh;
}

.forecast-sidebar {
  width: 200px; 
  flex-shrink: 0; 
  height: 100vh;
  overflow-y: auto;
}

.forecast-main-content {
  flex-grow: 1; 
  overflow-y: auto; 
}

.forecast-model-display {
  font-size: 0.8em; /* Adjust the font size as needed */
  text-align: center; /* Center align the text */
  margin-top: 10px; /* Add some space above the text */
}

.ForecastPercentageDisplay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.forecast-percentage-display-container h3 {
  margin-bottom: 20px; /* Adds some margin below the title */
  text-align: left;
}

.forecast-percentage-display-row { /* Add this class to each row of percentages */
  margin-bottom: 10px; /* Adds some margin below each row */
}

.forecast-and-kpis-container {
  display: flex;
  justify-content: space-between;
}

.forecast-percentage-display-container {
  height: calc(100% - 2rem); /* Adjust the value 2rem based on the actual height of the title and any padding/margins */
  padding: 1rem; /* Or any other spacing you want */
}

.forecast-chart-container {
  /* Make sure this container takes up only the necessary space */
  flex: 1;
}

.forecast-values-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 20px; /* Adjust as needed */
}

.forecast-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; /* Spacing between items */
}

.forecast-value label {
  margin-right: 10px; /* Spacing between label and value */
}

.forecasting-layout {
  display: flex;
}

.forecast-main-content {
  display: flex;
  flex-direction: column; /* Changed to column to stack chart and scorecards */
  flex-grow: 1;
  padding: 20px; /* Adjust as needed for your design */
}

.forecast-and-kpis-container {
  display: flex;
  justify-content: space-between;
}

.sidebar-card ul {
  list-style-type: none; 
  padding-left: 0; 
  font-size: 15px;   
}

.kpi-scorecard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3px; /* Adjust the space between grid items as needed */
  margin-top: 3px; /* Spacing between the chart and the grid */
}

.kpi {
  display: flex;
  flex-direction: column;
}

.chart-and-kpis {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will distribute space between the chart and KPIs */
  flex: 2; /* This will allow the chart and KPIs to take twice as much space as the percentages */
}

.chart-container {
  max-width: 600px; /* Adjust the maximum width as needed */
  max-height: 400px; /* Adjust the maximum height as needed */
  margin: auto; /* Center the chart if it's smaller than the max width */
}

.chart-and-kpis, .forecast-percentage-display-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.leaflet-container {
  z-index: 400; 
  left: 17px; 
  top: 21px; 
  height: calc(100vh - 40px); 
}



